<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Media Coverage</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <!-- <li>Media Coverage</li> -->
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section id="News" class="News-area uk-section">
    <div class="container">
        <h1 class="uk-text-center uk-text-bold">News 2024</h1>
        <div class="uk-grid">
            <div class="card uk-item">
                <a class="" href="news7">
                    <img src="../../../../assets/img/news/1.WebP" alt="" />
                    <div class="card-body">
                        <h5>
                            MAN 2 Banyumas student wins gold medal at 3rd
                            Indonesian International Invention Expo 2024
                        </h5>
                        <p>
                            The research team of Madrasah Aliyah Negeri (MAN) 2
                            Banyumas won the 3rd Indonesian International
                            Invention Expo (IIIEX) 2024 Gold medal for the Life
                            Science competition category. MAN 2 Banyumas
                            students in this event researched about student
                            character development.
                        </p>
                        <a href="news7" class="details-btn uk-button-primary"
                            >Detail</a
                        >
                    </div>
                </a>
            </div>
            <div class="card uk-item">
                <a class="" href="news8">
                    <img src="../../../../assets/img/news/2.WebP" alt="" />
                    <div class="card-body">
                        <h5>
                            Returning to Host IIIEX 2024, POLINES Gives Special
                            Prize for the Best Participant in High School Level!
                        </h5>
                        <p>
                            At the end of April to early May, IYSA again
                            organized one of our annual events which we named
                            Indonesia International Invention Expo (IIIEX) for
                            the 3rd time in one of the state universities in the
                            city of Semarang, namely Politeknik Negeri Semarang
                            (POLINES), this time IIIEX took place in the new
                            Polines building, namely the Polines Integrated
                            Lecture Building Ir. Ign. Darmojo.
                        </p>
                        <a href="news8" class="details-btn uk-button-primary"
                            >Detail</a
                        >
                    </div>
                </a>
            </div>
            <div class="card uk-item">
                <a class="" href="news9">
                    <img src="../../../../assets/img/news/3.WebP" alt="" />
                    <div class="card-body">
                        <h5>
                            Making DOC Enhancing, Five Al Muslim Junior High
                            School Students Won Gold Medals at IIIEX 2024
                        </h5>
                        <p>
                            Five Al Muslim Junior High School students won gold
                            medals at the Indonesia International Invention Expo
                            (IIIEX) 22024 competition organized by the Indonesia
                            Young Scientist Association (IYSA) and Politeknik
                            Negeri Semarang (Polines). These five students
                            include Amel, Azma, Annisa, Ibam, and Nadif who made
                            an innovative product Enhancing DOC (Divorce on
                            Cigarettes) through Collaborative Technological
                            Integration for Millennial Teenagers.
                        </p>
                        <a href="news9" class="details-btn uk-button-primary"
                            >Detail</a
                        >
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="container">
        <h1 class="uk-text-center uk-text-bold">News 2023</h1>
        <div class="uk-grid">
            <div class="card uk-item">
                <a class="" href="news1">
                    <img
                        src="../../../../assets/img/news/MAN1KUDUSNews.WebP"
                        alt=""
                    />
                    <div class="card-body">
                        <h5>
                            MAN 1 Kudus Research Team Wins Bronze at Indonesia
                            International Invention Expo 2023
                        </h5>
                        <p>
                            he research team of Madrasah Aliyah Negeri (MAN) 1
                            Kudus succeeded in presenting a bronze medal at the
                            Indonesia International Invention Expo (IIIEX) 2023.
                            This competition was organized by the Indonesian
                            Young Scientist Association (IYSA), at the Semarang
                            State Polytechnic.This competition is divided into
                            several levels of education, ranging from
                            elementary, secondary, to student and general
                            levels.
                        </p>
                        <a href="news1" class="details-btn uk-button-primary"
                            >Detail</a
                        >
                    </div>
                </a>
            </div>
            <div class="card uk-item">
                <a class="" href="news2">
                    <img
                        src="../../../../assets/img/news/MTSN3News.WebP"
                        alt=""
                    />
                    <div class="card-body">
                        <h5>
                            MTSN 3 Sanggau Wins Gold in International Scientific
                            Competition 2023
                        </h5>
                        <p>
                            The Research Team of Madrasah Tsanawiyah (MTs)
                            Negeri 3 Sanggau won first place by presenting a
                            Gold Medal in an international scientific
                            competition, Indonesia International Invention Expo
                            (IIIEX) 2023 in the Life Science category organized
                            by the Indonesian Young Scientists Association
                            (IYSA) through online or online.
                        </p>
                        <a href="news2" class="details-btn uk-button-primary"
                            >Detail</a
                        >
                    </div>
                </a>
            </div>
            <div class="card uk-item">
                <a class="" href="news3">
                    <img
                        src="../../../../assets/img/news/SMAMDANews.WebP"
                        alt=""
                    />
                    <div class="card-body">
                        <h5>
                            KIR Smamda International Champion Thanks to Organic
                            MSG from Kupang and Seaweed
                        </h5>
                        <p>
                            The Youth Scientific Group (KIR) of SMA Muhammadiyah
                            2 (Smamda), Sidoarjo succeeded in winning
                            international champion at the Indonesia
                            International Invention Expo (IIIEX) 2023 with the
                            product Organic Minosodium Glutamate (MSG) from
                            mussels and seaweed.
                        </p>
                        <a href="news3" class="details-btn uk-button-primary"
                            >Detail</a
                        >
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="container">
        <h1 class="uk-text-center uk-text-bold">News 2022</h1>
        <div class="uk-grid">
            <div class="card uk-item">
                <a class="" href="news4">
                    <img
                        src="../../../../assets/img/news/UNDIPNews.WebP"
                        alt=""
                    />
                    <div class="card-body">
                        <h5>
                            UNDIP Medical Students Win Gold Medal Indonesia
                            International Invention Expo (IIIEX) 2022
                        </h5>
                        <p>
                            A proud achievement was achieved by five students of
                            the Medical Study Program at the Indonesia
                            International Invention Expo (IIIEX)
                        </p>
                        <a href="news4" class="details-btn uk-button-primary"
                            >Detail</a
                        >
                    </div>
                </a>
            </div>
            <div class="card uk-item">
                <a class="" href="news5">
                    <img
                        src="../../../../assets/img/news/UNDIKSHANews.WebP"
                        alt=""
                    />
                    <div class="card-body">
                        <h5>
                            Raising the Issue of "Pecalang Segara", Undiksha
                            Team Wins Silver Medal at IIIEX Event
                        </h5>
                        <p>
                            Singaraja- International level achievements were
                            again achieved by students of Universitas Pendidikan
                            Ganesha (Undiksha). This time, it came from the
                            Indonesia International Invention Expo (IIIEX 2022)
                            which took place at Semarang State University on
                            October 15, 2022.
                        </p>
                        <a href="news5" class="details-btn uk-button-primary"
                            >Detail</a
                        >
                    </div>
                </a>
            </div>
            <div class="card uk-item">
                <a class="" href="news6">
                    <img
                        src="../../../../assets/img/news/UADNews.WebP"
                        alt=""
                    />
                    <div class="card-body">
                        <h5>
                            UAD Team Wins Gold Medal and Special Award at IIIEX
                            2022 Event
                        </h5>
                        <p>
                            The student team from the Chemical Engineering Study
                            Program of Universitas Ahmad Dahlan (UAD)
                            successfully won a gold medal and a special award
                            from the Malaysia Innovation Invention Creativity
                            Association (MIICA) at the Indonesia International
                            Invention Expo (IIIEX) 2022.
                        </p>
                        <a href="news6" class="details-btn uk-button-primary"
                            >Detail</a
                        >
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End News Area -->
