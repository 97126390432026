<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Media Coverage</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="listnews">News</a></li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>
                MAN 2 Banyumas student wins gold medal at 3rd Indonesian
                International Invention Expo 2024
            </h1>
            <img class="" src="../../../../assets/img/news/1.WebP" alt="" />
            <p>
                The research team of Madrasah Aliyah Negeri (MAN) 2 Banyumas won
                the 3rd Indonesian International Invention Expo (IIIEX) 2024
                Gold medal for the Life Science competition category. MAN 2
                Banyumas students in this event researched about student
                character development. The MAN 2 Banyumas Research Team
                consisted of five students, namely: Shiffa Luthfianie Ibrahim (X
                Research), Faizal Musridho Aldiansyah (X Research), Alexa
                Wiyanda Kaunang (X IPS 1), M. Luthfi Habiburrahman (X
                Vocational) and Hidayah Desti Khayrunnisa (XI MIPA 5).
            </p>
            <p>
                The 3rd Indonesian International Invention Expo started on March
                22, 2024. The final round and announcement of the winner took
                place on May 2, 2024 in Semarang, coinciding with National
                Education Day. The advisor and companion of the MAN 2 Banyumas
                Research Team, Adhitya Ridwan Budhi Prasetyo Nugroho revealed
                that IIIEX 2024 was attended by 341 teams from 15 countries. MAN
                2 Banyumas Student Research raised the theme “Character Building
                In Islamic Society: Developing Islamic Character With Abel-based
                Mathematics Mobile Learning For Senior High School Students".
            </p>
            <p>
                According to Adhitya, his nickname, this research is about ABEL
                and its relation to students' Islamic character development.
                “ABEL stands for Asyik Belajar Lingkaran which is associated
                with Islamic Character Development,” Adhitya added. Shiffa
                Luthfianie Ibrahim, as Team Leader, said that this research
                process really took a long time. "ABEL is not just a math
                learning application. But also as a means for students to learn
                to internalize religious values in everyday life,” explained
                Shiffa.
            </p>
            <p>
                "The features of this application always have religious values
                that can be instilled in others, especially students. In
                addition, the visuals are interesting so that it does not cause
                boredom. ABEL is a very useful and beneficial application for
                character building, especially Islamic character,” he added.
                Head of MAN 2 Banyumas, Muhamad Siswanto, appreciated the
                achievements of his students in the world research competition.
                "This is proof of the success of the research class, which is an
                implementation of the independent curriculum. Children are given
                the opportunity to develop their potential according to their
                talents and interests,” said Siswanto.
            </p>
            <p>
                "This success is also part of the efforts to make madrassas
                advanced in quality and worldwide. In the future, MAN 2 Banyumas
                will continue to move in order to accelerate the achievements of
                world-class madrasas,” he concluded. (Humas/Mufi)
            </p>
            <p>
                Source :
                <a
                    target="_blank"
                    href="https://kemenag.go.id/internasional/siswa-man-2-banyumas-raih-medali-emas-3rd-indonesian-internasional-invention-expo-2024-4OoWi"
                    >Click Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->
