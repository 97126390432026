<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Media Coverage</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="listnews">News</a></li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>
                Returning to Host IIIEX 2024, POLINES Gives Special Prize for
                the Best Participant in High School Level!
            </h1>
            <img class="" src="../../../../assets/img/news/2.WebP" alt="" />
            <p>
                At the end of April to early May, IYSA again organized one of
                our annual events which we named Indonesia International
                Invention Expo (IIIEX) for the 3rd time in one of the state
                universities in the city of Semarang, namely Politeknik Negeri
                Semarang (POLINES), this time IIIEX took place in the new
                Polines building, namely the Polines Integrated Lecture Building
                Ir. Ign. Darmojo. In this 3rd year, IIIEX was attended by 287
                teams from 15 countries including Indonesia, Iran, South Korea,
                Mexico, United States, United Arab Emirates, Turkey, Kazakhstan,
                Philippines, Thailand, Singapore, Democratic Republic of Congo,
                Vietnam, Malaysia, and Turkmenistan.
            </p>
            <p>
                IIIEX this time was held for 4 days, starting with the opening
                ceremony and judging for teams participating in the online
                competition on the first day (29/4), and continued with judging
                for teams participating in the offline competition on the 2nd
                day (30/4). If usually on the 3rd day we held an inventors talk,
                at the IIIEX event we replaced it with a seminar filled by one
                of the lecturers at Polines, Mr. Prayitno, M.T., Ph.D who
                discussed “From Sensors to Sentences: Large Language Models
                (LLMs) for Enhanced Industrial Internet of Things (IIoT) Data
                Analysis and Insights". The 3rd day of activities was followed
                by the announcement of winners for participants who participated
                in the online competition in the evening.
            </p>
            <p>
                As for the announcement for teams participating in the offline
                competition we held on the last day (2/5), there was something
                special in this competition because Polines gave an additional
                prize in the form of a Golden Ticket Free Entry Test which was
                won by a team from SMA Negeri 3 Semarang who brought the project
                “In Silico Test of Malacca Fruit (Phyllanthus emblica) and
                Soursop Leaves (Annona muricata L) Extracts as Drugs of Cervical
                Cancer”. Candidates of Cervical Cancer". Not only Polines has
                prepared additional prizes for the best team, IYSA has also
                prepared prizes for the winners and also prizes for the best
                teams. We prepared one grand prize in the form of Fully Funded
                to participate in the IYSA event which was won by the team from
                SMA NEGERI 5 SEMARANG.
            </p>
            <p>
                Source :
                <a
                    target="_blank"
                    href="https://www.depokpos.com/2024/05/kembali-menjadi-tuan-rumah-untuk-iiiex-2024-polines-memberikan-hadiah-spesial-untuk-peserta-terbaik-di-jenjang-sma/"
                    >Click Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->
