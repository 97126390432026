<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Media Coverage</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="listnews">News</a></li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>
                Making DOC Enhancing, Five Al Muslim Junior High School Students
                Won Gold Medals at IIIEX 2024
            </h1>
            <img class="" src="../../../../assets/img/news/3.WebP" alt="" />
            <p>
                Five Al Muslim Junior High School students won gold medals at
                the Indonesia International Invention Expo (IIIEX) 22024
                competition organized by the Indonesia Young Scientist
                Association (IYSA) and Politeknik Negeri Semarang (Polines).
                These five students include Amel, Azma, Annisa, Ibam, and Nadif
                who made an innovation product Enhancing DOC (Divorce on
                Cigarettes) through Collaborative Technological Integration for
                Millennial Teenagers. This innovation is a collaboration of
                digital technology and board games to attract the attention of
                young people how cigarettes work in the body. This product is
                based on the problems around us.
            </p>
            <p>
                "So, we hope that this innovative product can prevent problems
                that arise in the younger generation. And we also had the
                opportunity to meet directly with great students,” said one of
                the team members, Ibam, Monday (6/5/2024). In addition to the
                Gold Medal, Al Muslim Junior High School also won the Silver
                Medal and Bronze Medal. Silver Medal was achieved by Gisel,
                Izza, Nadif, Mecca, Hafy with the innovation product Analysis of
                the Validity and Effectiveness of the EVALITH (Est Java
                Hydraulic Labyrinth) Board Game to Increase Interest in
                Preserving East Java Culture.
            </p>
            <p>
                “This product is a collaboration between board games using the
                concept of East Java culture with the aim of introducing culture
                among young people,” said one of Gisel's team. Bronze Medal,
                achieved by Quinsha, Alma, Elina, Ayuri with the innovation
                product Exploration of SantaraPoli Game as a Supporting Medium
                for Strengthening Cultural Resilience in Elementary School
                Children. “The innovation product is a diversity-themed board
                game media to add insight into Indonesian culture to Al Muslim
                Elementary School students,” said one of Elina's members.
            </p>
            <p>
                Head of Al Muslim Junior High School Ika Sriyaningsih said that
                this was not the first time Al Muslim Junior High School had
                participated in the competition. "This is the fifth time our
                school has participated. This time there were two groups that
                participated online and one group that participated offline,
                where our students came to Semarang,” said Sriyaningsih.
                Sriyaningsih said the three groups had prepared the material for
                a month. With the results obtained, she was grateful for the
                medals obtained by her students.
            </p>
            <p>
                "Alhamdulillah, the hard work of the children for almost a month
                was paid off by successfully buying medals. Our students'
                participation is also an effort to prevent problems around them.
                Hopefully the children's achievements can be an encouragement
                for the children to further develop their respective potentials
                and make achievements,” explained Sriyaningsih.
            </p>
            <p>
                Source :
                <a
                    target="_blank"
                    href="https://ketik.co.id/berita/bikin-enhancing-doc-lima-siswa-smp-al-muslim-raih-medali-emas-di-iiiex-2024"
                    >Click Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->
