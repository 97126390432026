<!-- Start Main Banner -->
<!-- Indonesian Lang -->
<div id="home" class="uk-banner main-banner item-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="uk-container">
                <div class="main-banner-content">
                    <!-- <h3>2024</h3>     -->
                    <h1>Indonesia International<br />Invention Expo</h1>
                    <p>
                        Generasi Milenial merupakan individu yang mengalami
                        perkembangan terutama untuk emosional secara psikologis.
                        Sehingga kaum milenial merupakan potensi sumber daya
                        manusia untuk pembangunan baik saat ini maupun di masa
                        yang akan datang.
                    </p>

                    <a
                        href="/"
                        class="uk-button uk-button-default"
                        target=""
                        >Segera Hadir 2024</a
                    >
                    <!-- <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><span uk-icon="play"></span> Watch Video</a> -->
                </div>
            </div>
        </div>            
    </div>
</div>
<!-- End Main Banner -->
                 
<!-- Start Features Area -->
<section class="uk-features features-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m">
            <div class="uk-item">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-idea"></i>
                    </div>
                    <h3>Creativity</h3>
                    <div class="bar"></div>
                    <p>
                        Melakukan inovasi untuk mengembangkan jiwa kreativitas
                        dalam pelajar internasional.
                    </p>

                    <div class="dot-img">
                        <img
                            src="assets/img/dot.png"
                            alt="dot"
                            class="color-dot"
                        />
                        <img
                            src="assets/img/white-dot.png"
                            alt="dot"
                            class="white-dot"
                        />
                    </div>

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image" />
                        <img src="assets/img/shape2.svg" alt="image" />
                        <img src="assets/img/shape3.svg" alt="image" />
                        <img src="assets/img/shape1.svg" alt="image" />
                        <img src="assets/img/shape2.svg" alt="image" />
                        <img src="assets/img/shape3.svg" alt="image" />
                        <img src="assets/img/shape1.svg" alt="image" />
                        <img src="assets/img/shape3.svg" alt="image" />
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-features-box active">
                    <div class="icon">
                        <i class="flaticon-targeting"></i>
                    </div>
                    <h3>Skills</h3>
                    <div class="bar"></div>
                    <p>
                        Mengembangkan keterampilan penelitian ilmiah dan
                        semangat inovasi di kalangan mahasiswa internasional.
                    </p>

                    <div class="dot-img">
                        <img
                            src="assets/img/dot.png"
                            alt="dot"
                            class="color-dot"
                        />
                        <img
                            src="assets/img/white-dot.png"
                            alt="dot"
                            class="white-dot"
                        />
                    </div>

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image" />
                        <img src="assets/img/shape2.svg" alt="image" />
                        <img src="assets/img/shape3.svg" alt="image" />
                        <img src="assets/img/shape1.svg" alt="image" />
                        <img src="assets/img/shape2.svg" alt="image" />
                        <img src="assets/img/shape3.svg" alt="image" />
                        <img src="assets/img/shape1.svg" alt="image" />
                        <img src="assets/img/shape3.svg" alt="image" />
                    </div>
                </div>
            </div>

            <div class="uk-item">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-magnifying-glass"></i>
                    </div>
                    <h3>Experiences</h3>
                    <div class="bar"></div>
                    <p>
                        Bertukar pengalaman penemuan dan inovasi melalui
                        kepentingan bersama antara siswa terlepas dari mereka
                        kebangsaan.
                    </p>

                    <div class="dot-img">
                        <img
                            src="assets/img/dot.png"
                            alt="dot"
                            class="color-dot"
                        />
                        <img
                            src="assets/img/white-dot.png"
                            alt="dot"
                            class="white-dot"
                        />
                    </div>

                    <div class="animation-img">
                        <img src="assets/img/shape1.svg" alt="image" />
                        <img src="assets/img/shape2.svg" alt="image" />
                        <img src="assets/img/shape3.svg" alt="image" />
                        <img src="assets/img/shape1.svg" alt="image" />
                        <img src="assets/img/shape2.svg" alt="image" />
                        <img src="assets/img/shape3.svg" alt="image" />
                        <img src="assets/img/shape1.svg" alt="image" />
                        <img src="assets/img/shape3.svg" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features Area -->

<!-- <div class="separate">
    <div class="br-line"></div>
</div> -->

<!-- Start About Area -->
<!-- <section id="about" class="uk-about about-area uk-section">
    <div class="uk-container">
        <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s"
        >
            <div class="item">
                <div class="about-content">
                    <div class="uk-section-title section-title">
                        <span>About Us</span>
                        <h2>Leading the way in Creative Digital Agency</h2>
                        <div class="bar"></div>
                    </div>

                    <div class="about-text">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Best Digital Agency in the World</h3>
                        <p>
                            We provide marketing services to startups and small
                            businesses to looking for a partner of their digital
                            media, design & development, lead generation and
                            communications requirents. We work with you, not for
                            you. Although we have a great resources.
                        </p>
                        <p>
                            We are an experienced and talented team of
                            passionate consultants who live and breathe search
                            engine marketing.
                        </p>

                        <div class="signature">
                            <img
                                src="assets/img/signature.png"
                                alt="signature"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="about-img">
                    <img
                        src="assets/img/polines-2.jpg"
                        class="about-img1"
                        alt="about-img"
                    />
                    <img src="assets/img/1.png" class="shape-img" alt="shape" />

                    <a
                        routerLink="/about"
                        class="uk-button uk-button-default lax"
                        data-lax-preset="driftLeft"
                        >More About Us <i class="flaticon-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End About Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Services Area -->
<section id="services" class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>-</span>
            <h2>Kategori</h2>
            <div class="bar"></div>

            <a
                href="https://drive.google.com/file/d/1dG5M-Ex0PHizHv4ZH4u2i5vBUbxU5zYV/preview&embedded=true#:8.page.16"
                class="uk-button uk-button-default"
                target="_blank"
                >Semua Kategori</a
            >
        </div>

        <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s"
        >
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-plan"></i>
                    </div>
                    <h3>Agriculture & Aquaculture</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Energy</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-pay-per-click"></i>
                    </div>
                    <h3>Biotechnology</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-analytics-1"></i>
                    </div>
                    <h3>Chemistry</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services active">
                    <div class="icon">
                        <i class="flaticon-ux-design"></i>
                    </div>
                    <h3>Nanotechnology</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-stats"></i>
                    </div>
                    <h3>Environment</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-plus"></i>
                    </div>
                    <h3>Food</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-project"></i>
                    </div>
                    <h3>Electronics and IoT</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-data"></i>
                    </div>
                    <h3>Industry 4.0</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>
            
            <!-- new categories  -->
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-magnifying-glass"></i>
                    </div>
                    <h3>Social Science</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-bug"></i>
                    </div>
                    <h3>Biology</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-plan"></i>
                    </div>
                    <h3>Mathematic</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-tick"></i>
                    </div>
                    <h3>Physic and Engineering</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>

            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Life Science</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="#" class="link uk-position-cover"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<div class="uk-section-title section-title uk-text-center ">
    <iframe
        width="75%"
        height="415"
        src="https://www.youtube.com/embed/PQJ-_u1xyws?si=TIPczx9ZWJHzCW57"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
    ></iframe>
    <!-- <span>Process</span> -->
    <h2>After Movie IIIEX 2023</h2>
    <div class="bar"></div>
</div>

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Process Area -->
<section class="process-section uk-process uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>Process</span>
            <h2>Cara mudah untuk mengembangkan proyek Anda</h2>
            <div class="bar"></div>
        </div>

        <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s"
        >
            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="users"></i>
                    </div>
                    <h3>Registration</h3>
                    <p>
                        Isi data Anda seperti nama tim pemimpin Anda, sekolah
                        Anda, judul proyek, pembayaran pengiriman dan banyak
                        lagi.
                    </p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="cog"></i>
                    </div>
                    <h3>Receive LoA and Invoice</h3>
                    <p>
                        Kami mengirim LoA dan Faktur ke email Anda, jika Anda
                        tidak dapat menemukannya email LoA dan Faktur silahkan
                        cek di spam, atau hubungi tim kami.
                    </p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="unlock"></i>
                    </div>
                    <h3>Submit Requierements</h3>
                    <p>Anda harus memenuhi persyaratan untuk bergabung.</p>
                </div>
            </div>

            <div class="item">
                <div class="single-process-box">
                    <div class="icon">
                        <i uk-icon="heart"></i>
                    </div>
                    <h3>Project Presentation</h3>
                    <p>
                        Presentasi harus dalam bahasa inggris, jika membutuhkan
                        lebih silahkan hubungi admin tim kami terima kasih.
                    </p>
                </div>
            </div>

            <div class="item">
                <div class="process-arrow-icon">
                    <img src="assets/img/arrow.png" alt="image" />
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img2">
        <img src="assets/img/shape-img2.png" alt="image" />
    </div>
</section>
<!-- End Process Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Project Area -->
<section id="project" class="project-section uk-project uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title uk-text-center">
            <span>HOTELS</span>
            <h2>Hotel Recommendation</h2>
            <div class="bar"></div>
        </div>

        <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s"
        >
            <div class="item">
                <div class="single-project-box">
                    <a
                        href="https://www.google.com/maps/place/UNDIP+Inn+Hotel/@-7.0550146,110.4334104,17z/data=!4m8!3m7!1s0x2e708da391c838cd:0x2f3d956d05daff83!5m2!4m1!1i2!8m2!3d-7.0550146!4d110.4355991?hl=en"
                        target="_blank"
                        class="project-img"
                    >
                        <img
                            src="assets/img/hotels/undipinn.jpg"
                            width="380"
                            height="350"
                            alt="image"
                        />
                    </a>

                    <div class="project-content">
                        <h3>
                            <a
                                href="https://www.google.com/maps/place/UNDIP+Inn+Hotel/@-7.0550146,110.4334104,17z/data=!4m8!3m7!1s0x2e708da391c838cd:0x2f3d956d05daff83!5m2!4m1!1i2!8m2!3d-7.0550146!4d110.4355991?hl=en"
                                target="_blank"
                                >UNDIP INN</a
                            >
                        </h3>
                        <ul>
                            <li>
                                <a
                                    href="https://www.google.com/maps/place/UNDIP+Inn+Hotel/@-7.0550146,110.4334104,17z/data=!4m8!3m7!1s0x2e708da391c838cd:0x2f3d956d05daff83!5m2!4m1!1i2!8m2!3d-7.0550146!4d110.4355991?hl=en"
                                    target="_blank"
                                    >Jl. Prof. Soedarto, Tembalang, Kec.
                                    Tembalang, Kota Semarang, Jawa Tengah
                                    50275</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-project-box">
                    <a
                        href="https://www.google.com/maps/place/HAZOTEL+Semarang/@-7.0617275,110.4149833,17z/data=!3m1!4b1!4m8!3m7!1s0x2e708956d6063095:0xcbebafa464fbc3c7!5m2!4m1!1i2!8m2!3d-7.0617275!4d110.417172?hl=en"
                        target="_blank"
                        class="project-img"
                    >
                        <img
                            src="assets/img/hotels/hazotel.jpg"
                            alt="image"
                            width="380"
                            height="350"
                        />
                    </a>

                    <div class="project-content">
                        <h3>
                            <a
                                href="https://www.google.com/maps/place/HAZOTEL+Semarang/@-7.0617275,110.4149833,17z/data=!3m1!4b1!4m8!3m7!1s0x2e708956d6063095:0xcbebafa464fbc3c7!5m2!4m1!1i2!8m2!3d-7.0617275!4d110.417172?hl=en"
                                >Hazotel
                            </a>
                        </h3>
                        <ul>
                            <li>
                                <a
                                    href="https://www.google.com/maps/place/HAZOTEL+Semarang/@-7.0617275,110.4149833,17z/data=!3m1!4b1!4m8!3m7!1s0x2e708956d6063095:0xcbebafa464fbc3c7!5m2!4m1!1i2!8m2!3d-7.0617275!4d110.417172?hl=en"
                                    target="_blank"
                                    >Jl. Durian Raya No.27, Srondol Wetan, Kec.
                                    Banyumanik, Kota Semarang, Jawa Tengah 50263
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="single-project-box">
                    <a
                        href="https://www.google.com/maps?q=Baltis+Inn&source=lmns&bih=795&biw=1440&hl=en&sa=X&ved=2ahUKEwivrKevktT3AhUmjtgFHbHKDhAQ_AUoAXoECAEQAQ"
                        target="_blank"
                        class="project-img"
                    >
                        <img
                            src="assets/img/hotels/baltisinn2.jpg"
                            alt="image"
                            width="380"
                            height="350"
                        />
                    </a>

                    <div class="project-content">
                        <h3>
                            <a
                                href="https://www.google.com/maps?q=Baltis+Inn&source=lmns&bih=795&biw=1440&hl=en&sa=X&ved=2ahUKEwivrKevktT3AhUmjtgFHbHKDhAQ_AUoAXoECAEQAQ"
                                >Baltis INN
                            </a>
                        </h3>
                        <ul>
                            <li>
                                <a
                                    href="https://www.google.com/maps?q=Baltis+Inn&source=lmns&bih=795&biw=1440&hl=en&sa=X&ved=2ahUKEwivrKevktT3AhUmjtgFHbHKDhAQ_AUoAXoECAEQAQ"
                                    target="_blank"
                                    >Jl. Banjarsari No.21, Tembalang, Kec.
                                    Tembalang, Kota Semarang, Jawa Tengah 50275
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-circle-img1">
        <img src="assets/img/shape-img1.png" alt="image" />
    </div>
</section>
<!-- End Project Area -->

<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Partner Area -->
<div class="partner-area uk-section uk-padding-remove-top">
    <div class="uk-container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="item">
                <a href="#">
                    <img src="assets/img/partner/iysa.png" alt="image" />
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img
                        src="assets/img/partner/politeknik_semarang.png"
                        alt="image"
                    />
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner/ibs.png" alt="image" />
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner/ociip.png" alt="image" />
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner/ayrid.png" alt="image" />
                </a>
            </div>

            <div class="item">
                <a href="#">
                    <img src="assets/img/partner/logo_atast.png" alt="image" />
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->


<div class="separate">
    <div class="br-line"></div>
</div>

<!-- Start Contact Area -->
<section id="contact" class="contact-area uk-contact uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>Mari Bicara</span>
            <h2>Hubungi Kami</h2>
        </div>

        <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s"
        >
            <div class="item">
                <div class="map-img">
                    <img src="assets/img/map.png" alt="map" />

                    <div class="location uk-location1">
                        <a
                            href="https://goo.gl/maps/gEzFyE9gtXEyRfA26"
                            class="active"
                            target="_blank"
                        >
                            <div class="location-info">
                                <h5>Depok, Indonesia</h5>
                                <span> Jl. Kemang No. 63 RT 03 RW 06</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="item">
                <form id="contactForm" action="https://formspree.io/f/xaygobok" method="POST">
                    <div
                        class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s"
                    >
                        <div class="item uk-margin">
                            <input
                                type="text"
                                class="uk-input"
                                name="name"
                                id="name"
                                placeholder="Name"
                                required
                            />
                        </div>

                        <div class="item uk-margin">
                            <input
                                type="email"
                                class="uk-input"
                                name="email"
                                id="email"
                                placeholder="Email"
                                required
                            />
                        </div>

                        <div class="item uk-margin">
                            <input
                                type="text"
                                class="uk-input"
                                placeholder="Phone"
                                required
                            />
                        </div>

                        <!-- <div class="item uk-margin">
                            <input
                                type="text"
                                class="uk-input"
                                name="subject"
                                id="subject"
                                placeholder="Subject"
                            />
                        </div> -->
                    </div>

                    <div class="item">
                        <textarea
                            name="message"
                            class="uk-textarea"
                            id="message"
                            cols="30"
                            rows="4"
                            placeholder="Your Message"
                            required
                        ></textarea>
                    </div>

                    <button type="submit" class="uk-button uk-button-default">
                        Kirim
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
