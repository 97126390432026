<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Media Coverage</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="listnews">News</a></li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>UNDIP Medical Students Win Gold Medal Indonesia International Invention Expo (IIIEX) 2022</h1>
            <img class="" src="../../../../assets/img/news/UNDIPNews.WebP" alt="">
            <p>
                A proud achievement was achieved by five students of the Medical Study Program at the Indonesia
                International Invention Expo (IIIEX). Indonesia International Invention Expo (IIIEX) is one of the
                international competition events organized by the Indonesian Young Scientists Association (IYSA). This
                activity aims to provide opportunities for students and students from various countries to showcase
                their research works / results in front of judges and other visitors.
            </p>
            <p>
                This activity will be held on October 14 – 17, 2022 at the Semarang State Polytechnic. This prestigious
                event was attended by finalists, namely 140 teams from 25 countries, including Indonesia, Malaysia,
                Thailand, Iran, Belarus, India, Bangladesh, Vietnam, Mexico, the Philippines, Hong Kong, China, Turkey,
                the United States and South Korea. The race lasts 4 days. In this activity, students will explain the
                results of their research and the work they present either in person or online and the Jury team will
                provide constructive assessment and input.
            </p>
            <p>
                The UNDIP Faculty of Medicine team sent five delegates who were 7th semester students of the Medical
                Study Program, namely Renataningtyas Ramadhiani (2019), Ardiyana Ar (2019), Revi Anisatur Rosyidah
                (2019), Syibra Fakhira Nur Sabrina (2019), and Malinda Evelyn (2019). By presenting a research entitled
                "The Correlation of Coping Resilience Levels With The Severity Of COVID-19 Infected Patients in
                Semarang", the Faculty of Medicine UNDIP Team won the Gold Medal in the Social Science Category.
            </p>
            <p>
                At the event, the UNDIP Team exhibited a website, namely "Resilient Coping Website" which is a
                development on the results of the research. Under the direction of dr. Desy Armalina, M.Si.Med and dr.
                Widodo Bachelor of US, MKM., S.KJ as supervisors, the UNDIP Team succeeded in bringing prestigious
                achievements that are proud. Not stopping here, several other awards were also won, including the IYSA
                Special Awards and IYSA Semi Grand Awards Free Registration IYMIA IPB Bogor 2023.
            </p>
            <p>Source : <a target="_blank"
                    href="http://psked.fk.undip.ac.id/2020/2022/10/20/mahasiswa-kedokteran-undip-raih-gold-medal-indonesia-international-invention-expo-iiiex/">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->