<!-- Start Footer Area -->
<footer class="footer-area {{ location == '/' ? '' : 'uk-dark' }} uk-footer">
    <div class="uk-container">
        <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-4@m uk-child-width-1-2@s"
        >
            <div class="item">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/iiiex2.png" alt="logo" />
                        </a>
                    </div>
                    <p>
                        The Indonesian Young Scientist Association (IYSA) provides opportunities for millennials to showcase their competence, talents, and passion, particularly in the fields of science and technology. IYSA focuses on organizing various international science activities, including invention and innovation competitions.
                    </p>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>
                    <div class="bar"></div>

                    <ul class="contact-info">
                        <li>
                            <a href="/">Home</a>
                        </li>
                        <!-- <li>
                            <a
                                href="https://drive.google.com/file/d/1HJOPbxEnJLhLCabDeE7DI5tIAnzkAKbH/view?usp=sharing"
                                target="_blank"
                                >Guide Book</a
                            >
                        </li> -->
                    </ul>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Other</h3>
                    <div class="bar"></div>

                    <ul class="contact-info">
                        <li>
                            <a href="https://iysa.or.id" target="_blank"
                                >Our Website</a
                            >
                        </li>
                    </ul>
                </div>
            </div>

            <div class="item">
                <div class="single-footer-widget">
                    <h3>Contact</h3>
                    <div class="bar"></div>

                    <ul class="contact-info">
                        <li>
                            <a href="mailto:iiiex.iysa@gmail.com"
                                >iiiex.iysa@gmail.com</a
                            >
                        </li>
                        <li>
                            <a href="https://wa.me/6281770914129">WhatsApp</a>
                        </li>
                        <li>
                            <a href="tel:+6281770914129">+62 817-7091-4129</a>
                        </li>
                    </ul>
                    <ul class="social">
                        <li>
                            <a href="#" target="_blank"
                                ><i class="flaticon-logo"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="flaticon-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="flaticon-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="flaticon-logo-1"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div
                class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s"
            >
                <div class="item">
                    <p>© COPYRIGHT. All Rights Reserved, 2022</p>
                </div>

                <div class="item">
                    <ul>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
            <div class="go-top"><i class="flaticon-chevron"></i></div>
        </div>
    </div>

    <div class="br-line"></div>
    <div class="footer-shape1">
        <img src="assets/img/footer-shape1.png" alt="shape" />
    </div>
    <div class="footer-shape2">
        <img src="assets/img/footer-shape2.png" alt="shape" />
    </div>
</footer>
<!-- End Footer Area -->
