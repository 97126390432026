<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Media Coverage</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="listnews">News</a></li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>Raising the Issue of "Pecalang Segara", Undiksha Team Wins Silver Medal at IIIEX Event</h1>
            <img class="" src="../../../../assets/img/news/UNDIKSHANews.WebP" alt="">
            <p>
                Singaraja- International level achievements were again achieved by students of Universitas Pendidikan
                Ganesha (Undiksha). This time, it came from the Indonesia International Invention Expo (IIIEX 2022)
                which took place at Semarang State University on October 15, 2022. The Undiksha team consisting of Kadek
                Nova Suadnyana from the History Education Study Program, Kadek Desika Natalia from the Biology Study
                Program, I Komang Andi Suryawan from the Elementary School Teacher Education Study Program (PGSD), Ni
                Made Rida Divayani from the History Education Study Program, and Kadek Faldy Dwi Surastawan from the D3
                Library Study Program managed to bring home a silver medal.
            </p>
            <p>
                In the event, the Undiksha team competed with 145 teams from 20 countries. The work presented was about
                the research of Pecalang Segara Pemuteran Village. The team leader, Nova Suadnyana explained that the
                work was made because it saw the uniqueness of Pemuteran Village which has Pecalang in marine security.
                In general, usually pecalang in Bali only serve when there is a religious ceremony, while pecalang
                immediately focuses mainly on protecting sea waters to prevent prohibited actions. "We are trying to
                study this from the academic side," he said, Wednesday (10/19/2022).
            </p>
            <p>
                This 5th semester student hopes that through this work, the Bali Provincial Government can make
                regulations so that each traditional village in Bali forms Pecalang Segara in each Regency / City as an
                effort to maintain and preserve the beach / sea which is also an attraction for tourists.Furthermore,
                under the guidance of I Putu Hendra Mas Martayana, S.Pd., M.A., the preparation for participating in
                this competition took about 2 months, starting from the registration stage, script writing, to the
                competition. Although there were obstacles that made him and his team unable to take part in the
                competition offline, he admitted that it paid off with the awards that were won.
            </p>
            <p>
                Vice Rector for Student Affairs, Public Relations, and Alumni, Prof. Dr. I Wayan Suastra, M.Pd.,
                expressed his appreciation for this achievement. He hopes that this achievement can continue to increase
                students' motivation to compete. "We at the leadership are also proud of this achievement. Hopefully
                more students will succeed in achieving achievements, "he said. (HMS)
            </p>
            <p>Source : <a target="_blank"
                    href="https://fhis.undiksha.ac.id/angkat-soal-pecalang-segara-tim-undiksha-raih-medali-perak-di-ajang-iiiex/">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->