<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>List of Winners 2024</h1>
        <ul>
            <li><a href="/kategori24">Previous Page</a></li>
            <li>Event Level</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section id="services" class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>-</span>
            <h2>
                Choose based on the Level of the Online event you are participating in
            </h2>
            <div class="bar"></div>
        </div>
        <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s"
        >
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>University</h3>
                    <i class="flaticon-right link-btn"></i>
                    <a
                        href="https://drive.google.com/file/d/1DIQmVE0gTEYDZk_eLXfQN0pgm1otsdpc/view?usp=sharing"
                        target="_blank"
                        class="link uk-position-cover"
                    ></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Senior high School</h3>
                    <i class="flaticon-right link-btn"></i>
                    <a
                        href="https://drive.google.com/file/d/1z6lhyW4jCwIl6Ndd9j1CBQRD-fUEYUtc/view?usp=sharing"
                        target="_blank"
                        class="link uk-position-cover"
                    ></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Junior High School</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a
                        href="https://drive.google.com/file/d/1B9ourebVmMJGGTvyrgdxdorQHVZ4JWga/view?usp=sharing"
                        class="link uk-position-cover"
                        target="_blank"
                    ></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Elementary School</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a
                        href="https://drive.google.com/file/d/1YPuH9iITEhDSksnOz_dOmjgg3tOy-GND/view?usp=sharing"
                        class="link uk-position-cover"
                        target="_blank"
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>
