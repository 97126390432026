<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Media Coverage</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="listnews">News</a></li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>MTSN 3 Sanggau Wins Gold in International Scientific Competition 2023</h1>
            <img class="" src="../../../../assets/img/news/SMAMDANews.WebP" alt="">
            <p>
                The Youth Scientific Group (KIR) of SMA Muhammadiyah 2 (Smamda), Sidoarjo succeeded in winning
                international champion at the Indonesia International Invention Expo (IIIEX) 2023 with the product
                Organic Minosodium Glutamate (MSG) from mussels and seaweed.This competition was held at the Semarang
                State Polytechnic, 25-28 August 2023. The competition was attended by 241 teams from various countries
                including Indonesia, Malaysia, the Philippines, Vietnam,Thailand, Iran, Mexico, the United States, South
                Korea and Romania.
            </p>
            <p>
                Sidoarjo High School took home the Gold Medal in the Food Science category. The research title is
                High-Protein Organic MSG from Mussels (Musculista Senhausia) and Seaweed (Eucheuma Cottonii) Extracts as
                an Antioxidant to Free Radicals.This product is high protein organic MSG from mussel and seaweed
                extracts as an antioxidant to capture free radicals.This work was made by Andhin Alfianti Yunida class
                XII-1, Septian Aulia Najwa class XII-4, Zahra Putri Yega class XII-5, and M. Taufik class XII-1
            </p>
            <p>
                Teacher instructor Ernawati Kristiningrum ST MPd explained that the creation of this innovative work was
                motivated by the large number of mussels in Sidoarjo Regency and Indonesia, the largest seaweed habitat
                in the world."We chose kupang because it is local wisdom from Sidoarjo, specifically in Balongdowo
                village, Candi District, where most of the residents make their living as kupang fishermen. "And we
                chose seaweed because several years ago the Indonesian Export Financing Institute (LPEI) or Indonesia
                Exim Bank inaugurated the first Seaweed Foreign Exchange Village program in Indonesia in Sidoarjo," he
                said.
            </p>
            <p>
                He added that the Seaweed Foreign Exchange Village Program is located at the Sumber Mulyo Cooperative,
                Kupang Village, Jabon District, Sidoarjo Regency, so the basic ingredients are easy to obtain.This
                chemistry teacher explained that research results show that mussels contain protein and seaweed contains
                antioxidants."The high protein content in mussels can be useful for repairing damaged cells, creating
                new cells and body tissues," he added.
            </p>
            <p>
                Meanwhile, the antioxidant content in red seaweed, he continued, functions as an antidote to free
                radicals. With this content, KIR Smamda designed organic MSG to enhance the taste of food.Andhin
                Alfianti Yunida, one of the KIR class XII-1 members, explained that there are 4 stages in the process of
                making Organic MSG."The first process is extracting mussel meat by heating it with water, followed by
                the process of extracting Monosodium Glutamate from seaweed," he explained.He added that the seaweed is
                dried first by oven at 60℃ for 2 hours or dried in the sun until dry. The dried seaweed is then crushed
                using a blender until smooth.After that, it is sieved until it produces a particle size of 60 mesh,
                followed by the third process of crystallization of kupang meat and seaweed extract and finally the
                mixture is drying."The powdered dough is weighed and packaged in sachets, then sealed until it is
                tightly closed," explained Andhin.
            <p>
                Deputy Principal for Smamda Curriculum, Alful Musrifah MPd, admitted that he was very proud of the
                achievements achieved by the KIR team."They have shown extraordinary dedication and tenacity in
                developing real solutions by making Organic MSG by utilizing local wisdom in Sidoarjo," he said.He hopes
                that this achievement can inspire other young generations to contribute to positive change.In an effort
                to support the KIR team, Smamda has provided access to the facilities and resources needed to carry out
                in-depth research.
            </p>
            <p>
                The research team was given the opportunity to collaborate with teachers who are experts in various
                fields, helping them broaden their horizons and deepen their understanding of the issues they
                research.Apart from that, Smamda also provides support in terms of funding for conducting research. This
                allows teams to collect data, conduct experiments, and develop financially better solutions. The entire
                research process receives continuous supervision and guidance from the KIR supervisor, providing
                encouragement to maintain the quality and integrity of the research.
            </p>
            <p></p>
            <p>Source : <a target="_blank"
                    href="https://suryakabar.com/2023/08/29/kir-smamda-juara-internasional-berkat-msg-organik-dari-kupang-dan-rumput-laut/">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->