<!-- Start Mobile Navbar -->
<div
    id="offcanvas-flip"
    class="mobile-navbar uk-mobile-navbar"
    uk-offcanvas="flip: true; overlay: true"
>
    <div class="uk-offcanvas-bar">
        <button class="uk-offcanvas-close" type="button" uk-close></button>

        <nav
            class="uk-navbar-container"
            data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true"
        >
            <ul class="uk-navbar-nav">
                <li>
                    <a routerLink="/" routerLinkActive="uk-active">Home</a>
                </li>
                <li>
                    <a href="/lowtahun" 
                        >List of Winners</a
                    >
                </li>
                
                <!-- <li>
                    <a href="https://drive.google.com/file/d/1icnWua-trtsmZgUV_3nmal1w1dSU6Yyr/view?usp=sharing"
                        target="_blank">Final Result</a>
                </li> -->

                <li>
                    <a href="#" uk-icon="icon: triangle-down">Media Coverage</a>
                    <div uk-dropdown>
                        <ul class="uk-nav uk-dropdown-nav">
                            <li>
                                <a href="listnews">News</a>
                            </li>
                            <li>
                                <a
                                    href="https://drive.google.com/file/d/1IB-ZzYmKkfmGPWDqJluaG-Fj8Mi37-C4/view?usp=sharing"
                                    rel="noreferrer"
                                    target="_blank"
                                    >Press Release 2024</a
                                >
                            </li>
                            <li>
                                <a
                                    href="https://drive.google.com/file/d/14e3kF5ZJN-OIriQLskOCweBjjt2cUcqr/view?usp=sharing"
                                    rel="noreferrer"
                                    target="_blank"
                                    >Press Release 2023</a
                                >
                            </li>
                            <li>
                                <a
                                    href="https://drive.google.com/file/d/1Z-i06hjzXCh-BBcWTQzWhF4ZCos0YvzC/view?usp=sharing"
                                    rel="noreferrer"
                                    target="_blank"
                                    >Press Release 2022</a
                                >
                            </li>

                            <!-- <li>
                                <a href="#" target="_blank">Curation 2023</a>
                            </li> -->
                        </ul>
                    </div>
                </li>
                <!-- </li> -->
                <!-- <li>
                    <a href="https://drive.google.com/file/d/1Z-i06hjzXCh-BBcWTQzWhF4ZCos0YvzC/view?usp=sharing"
                        target="_blank">Press Release</a>
                </li> -->
                <li>
                    <a href="#" uk-icon="icon: triangle-down">Curation</a>
                    <div uk-dropdown>
                        <ul class="uk-nav uk-dropdown-nav">
                            <li>
                                <a
                                    href="https://drive.google.com/drive/folders/1gjvxKfa_7fjNFsFz8dBlAR08UFB7VjdF?usp=sharing"
                                    rel="noreferrer"
                                    target="_blank"
                                    >Curation 2023</a
                                >
                            </li>
                            <li>
                                <a
                                    href="https://drive.google.com/drive/folders/1SMDBmCLlPAnoVo4BNWngSODuQKXjdQbd?usp=sharing"
                                    rel="noreferrer"
                                    target="_blank"
                                    >Curation 2022</a
                                >
                            </li>
                            <!-- <li>
                                <a href="#" target="_blank">Curation 2023</a>
                            </li> -->
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#" uk-icon="icon: triangle-down">Gallery</a>
                    <div uk-dropdown>
                        <ul class="uk-nav uk-dropdown-nav">
                            <li>
                                <a
                                    href="https://drive.google.com/drive/folders/1O322rF2zpsPNPLwanAx53o5-rYbyLT6k?usp=sharing"
                                    rel="noreferrer"
                                    target="_blank"
                                    >Gallery 2024</a
                                >
                            </li>
                            <li>
                                <a
                                    href="https://drive.google.com/drive/folders/1i11pxMe2d20cOWZJ1sJ5wz3R9j_njGTm?usp=drive_link"
                                    rel="noreferrer"
                                    target="_blank"
                                    >Gallery 2023</a
                                >
                            </li>
                            <!-- <li>
                                <a href="#" target="_blank">Curation 2023</a>
                            </li> -->
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#" uk-icon="icon: triangle-down">Certificate</a>
                    <div uk-dropdown>
                        <ul class="uk-nav uk-dropdown-nav">
                            <li>
                                <a
                                    href="https://drive.google.com/drive/folders/1dbyRuM34R9hiI5GJpy-PBZDtbFJnT6Tu?usp=sharing"
                                    rel="noreferrer"
                                    target="_blank"
                                    >Certificate Supervisor</a
                                >
                            </li>

                            <!-- <li>
                                <a href="#" target="_blank">Curation 2023</a>
                            </li> -->
                        </ul>
                    </div>
                </li>

                <!-- <li>
                    <a href="https://drive.google.com/drive/folders/1wgTx-zxM41gkZsJMNwgAYzKeiuUwXx4u?usp=sharing"
                        target="_blank">Certificate</a>
                </li> -->
                <!-- <li>
                    <a routerLink="/about" routerLinkActive="uk-active"
                        >About</a
                    >
                </li>
                <li>
                    <a href="#" uk-icon="icon: triangle-down">Services</a>
                    <div uk-dropdown>
                        <ul class="uk-nav uk-dropdown-nav">
                            <li>
                                <a
                                    routerLink="/services"
                                    routerLinkActive="uk-active"
                                    >Services</a
                                >
                            </li>
                            <li>
                                <a
                                    routerLink="/service-details"
                                    routerLinkActive="uk-active"
                                    >Service Details</a
                                >
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a href="#" uk-icon="icon: triangle-down">Projects</a>
                    <div uk-dropdown>
                        <ul class="uk-nav uk-dropdown-nav">
                            <li>
                                <a
                                    routerLink="/projects"
                                    routerLinkActive="uk-active"
                                    >Project</a
                                >
                            </li>
                            <li>
                                <a
                                    routerLink="/project-details"
                                    routerLinkActive="uk-active"
                                    >Project Details</a
                                >
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a routerLink="/team" routerLinkActive="uk-active">Team</a>
                </li>
                <li>
                    <a routerLink="/testimonials" routerLinkActive="uk-active"
                        >Testimonials</a
                    >
                </li>
                <li>
                    <a href="#" uk-icon="icon: triangle-down">Blog</a>
                    <div uk-dropdown>
                        <ul class="uk-nav uk-dropdown-nav">
                            <li>
                                <a
                                    routerLink="/blog"
                                    routerLinkActive="uk-active"
                                    >Blog</a
                                >
                            </li>
                            <li>
                                <a
                                    routerLink="/blog-details"
                                    routerLinkActive="uk-active"
                                    >Blog Details</a
                                >
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <a routerLink="/contact" routerLinkActive="uk-active"
                        >Contact</a
                    >
                </li> -->
                <!-- <li>
                    <a href="#" uk-icon="icon: triangle-down">Language</a>
                    <div uk-dropdown>
                        <ul class="uk-nav uk-dropdown-nav">
                            <li>
                                <a routerLink="/">En</a>
                            </li>
                            <li>
                                <a routerLink="/id" routerLinkActive="uk-active">Id</a>
                            </li>
                        </ul>
                    </div>
                </li> -->
            </ul>
        </nav>
    </div>
</div>
<!-- End Mobile Navbar -->

<!-- Start Navbar Area -->
<header
    class="header-area {{ navClass }}"
    data-uk-sticky="top: 0; animation: uk-animation-slide-top;"
>
    <div class="uk-container">
        <div class="uk-navbar">
            <div class="logo uk-navbar-left">
                <a routerLink="/">
                    <img src="assets/img/{{ logo }}" alt="logo" />
                </a>
            </div>

            <div
                class="uk-navbar-toggle"
                id="navbar-toggle"
                uk-toggle="target: #offcanvas-flip"
            >
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="navbar uk-navbar-right">
                <nav
                    class="uk-navbar-container"
                    data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true"
                >
                    <ul class="uk-navbar-nav">
                        <li>
                            <a routerLink="/" routerLinkActive="uk-active"
                                >Home</a
                            >
                        </li>
                        <!-- <li>
                            <a href="https://drive.google.com/drive/folders/1eJFWnPnG2Nb0oHTkf0aN5Nue21oOy2E5?usp=sharing"
                                target="_blank">Final Result</a>
                        </li> -->
                        <li>
                            <a href="/lowtahun" 
                                >List of Winners</a
                            >
                        </li>
                        <!-- <li>
                            <a href="https://drive.google.com/file/d/1Z-i06hjzXCh-BBcWTQzWhF4ZCos0YvzC/view?usp=sharing"
                                target="_blank">Press Release</a>
                        </li> -->
                        <li>
                            <a href="#" uk-icon="icon: triangle-down"
                                >Media Coverage</a
                            >
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a href="listnews">News</a>
                                        <a
                                            href="https://drive.google.com/file/d/1IB-ZzYmKkfmGPWDqJluaG-Fj8Mi37-C4/view?usp=sharing"
                                            rel="noreferrer"
                                            target="_blank"
                                            >Press Release 2024</a
                                        >
                                        <a
                                            href="https://drive.google.com/file/d/14e3kF5ZJN-OIriQLskOCweBjjt2cUcqr/view?usp=sharing"
                                            rel="noreferrer"
                                            target="_blank"
                                            >Press Release 2023</a
                                        >
                                        <a
                                            href="https://drive.google.com/file/d/1Z-i06hjzXCh-BBcWTQzWhF4ZCos0YvzC/view?usp=sharing"
                                            rel="noreferrer"
                                            target="_blank"
                                            >Press Release 2022</a
                                        >
                                    </li>
                                    <!-- <li>
                                        <a href="#" target="_blank">Curation 2023</a>
                                    </li> -->
                                </ul>
                            </div>
                        </li>
                        <!-- <li> -->
                        <!-- <a href="#" uk-icon="icon: triangle-down">Curation</a>
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav"> -->
                        <!-- <li>
                                        <a href="#" target="_blank">Curation 2022</a>
                                    </li> -->
                        <!-- <li>
                                        <a href="#" target="_blank">Curation 2023</a>
                                    </li> -->
                        <!-- </ul>
                            </div> -->
                        <!-- </li> -->
                        <li>
                            <a href="#" uk-icon="icon: triangle-down"
                                >Curation</a
                            >
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a
                                            href="https://drive.google.com/drive/folders/1fnxmag1HhrYxDF-Kl8X3Cfgc0BErOVfZ?usp=drive_link"
                                            rel="noreferrer"
                                            target="_blank"
                                            >Curation 2024</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                            href="https://drive.google.com/drive/folders/1gjvxKfa_7fjNFsFz8dBlAR08UFB7VjdF?usp=sharing"
                                            rel="noreferrer"
                                            target="_blank"
                                            >Curation 2023</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                            href="https://drive.google.com/drive/folders/1SMDBmCLlPAnoVo4BNWngSODuQKXjdQbd?usp=sharing"
                                            rel="noreferrer"
                                            target="_blank"
                                            >Curation 2022</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a href="#" uk-icon="icon: triangle-down"
                                >Gallery</a
                            >
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a
                                            href="https://drive.google.com/drive/folders/1O322rF2zpsPNPLwanAx53o5-rYbyLT6k?usp=sharing"
                                            rel="noreferrer"
                                            target="_blank"
                                            >Gallery 2024</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                            href="https://drive.google.com/drive/folders/1i11pxMe2d20cOWZJ1sJ5wz3R9j_njGTm?usp=drive_link"
                                            rel="noreferrer"
                                            target="_blank"
                                            >Gallery 2023</a
                                        >
                                    </li>
                                    <!-- <li>
                                        <a href="#" target="_blank">Curation 2023</a>
                                    </li> -->
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a href="#" uk-icon="icon: triangle-down"
                                >Certificate</a
                            >
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav">
                                    <!-- <li>
                                        <a
                                            href="https://drive.google.com/drive/folders/1wgTx-zxM41gkZsJMNwgAYzKeiuUwXx4u?usp=sharing"
                                            rel="noreferrer"
                                            target="_blank"
                                            >Certificate Supervisor</a
                                        >
                                    </li> -->

                                    <li>
                                        <a
                                            href="https://drive.google.com/drive/folders/1dbyRuM34R9hiI5GJpy-PBZDtbFJnT6Tu?usp=sharing"
                                            rel="noreferrer"
                                            target="_blank"
                                            >Certificate Supervisor</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a href="FAQ">FAQ</a>
                        </li>
                        <!-- 
                        <li>
                            <a href="https://drive.google.com/drive/folders/1wgTx-zxM41gkZsJMNwgAYzKeiuUwXx4u?usp=sharing"
                                target="_blank">Certificate</a>
                        </li> -->
                        <!-- <li>
                            <a routerLink="/about" routerLinkActive="uk-active"
                                >About</a
                            >
                        </li>
                        <li>
                            <a href="#" uk-icon="icon: triangle-down"
                                >Services</a
                            >
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a
                                            routerLink="/services"
                                            routerLinkActive="uk-active"
                                            >Services</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                            routerLink="/service-details"
                                            routerLinkActive="uk-active"
                                            >Service Details</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a href="#" uk-icon="icon: triangle-down"
                                >Projects</a
                            >
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a
                                            routerLink="/projects"
                                            routerLinkActive="uk-active"
                                            >Project</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                            routerLink="/project-details"
                                            routerLinkActive="uk-active"
                                            >Project Details</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a routerLink="/team" routerLinkActive="uk-active"
                                >Team</a
                            >
                        </li>
                        <li>
                            <a
                                routerLink="/testimonials"
                                routerLinkActive="uk-active"
                                >Testimonials</a
                            >
                        </li>
                        <li>
                            <a href="#" uk-icon="icon: triangle-down">Blog</a>
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a
                                            routerLink="/blog"
                                            routerLinkActive="uk-active"
                                            >Blog</a
                                        >
                                    </li>
                                    <li>
                                        <a
                                            routerLink="/blog-details"
                                            routerLinkActive="uk-active"
                                            >Blog Details</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a
                                routerLink="/contact"
                                routerLinkActive="uk-active"
                                >Contact</a
                            >
                        </li> -->
                        <!-- <li>
                            <a href="#" uk-icon="icon: triangle-down"
                                >Language</a
                            >
                            <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a routerLink="/">En</a>
                                    </li>
                                    <li>
                                        <a
                                            routerLink="/id"
                                            routerLinkActive="uk-active"
                                            >Id</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </li> -->
                    </ul>
                </nav>

                <!-- <div class="lang">
                    <form>
                        <div>
                            <select>
                                <option value="eng">En</option>
                                <option value="ge">Ge</option>
                            </select>
                        </div>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</header>
<!-- End Navbar Area -->
