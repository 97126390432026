<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Media Coverage</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="listnews">News</a></li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>UAD Team Wins Gold Medal and Special Award at IIIEX 2022 Event</h1>
            <img class="" src="../../../../assets/img/news/UADNews.WebP" alt="">
            <p>
                The student team from the Chemical Engineering Study Program of Universitas Ahmad Dahlan (UAD)
                successfully won a gold medal and a special award from the Malaysia Innovation Invention Creativity
                Association (MIICA) at the Indonesia International Invention Expo (IIIEX) 2022. This competition will be
                held in Semarang on October 14-17, 2022.The team was chaired by Abdul Aziz and consisted of three
                people, namely Siti Nur Aini, Imam Mahdi, and Maqfiro Revi W.T. In addition, Ir. Adi Permadi, S.T.,
                M.T., M.Farm., Ph.D. was lined up as a supervisor to accompany the team during the competition.
            </p>
            <p>
                With the title "Utilization of Waste Burning for the Production of Distilled Water and Salt", UAD
                successfully competed with 145 other teams from 20 countries. In an interview via WhatsApp (25-10-2022),
                Adi Permadi as the supervisor said that this achievement is a tradition of winning in many competitions
                for each batch of UAD Chemical Engineering students.A special award from MIICA makes the team worthy of
                the double kill label in this event. According to Adi, there are three things that MIICA considers to
                give the award, namely innovation, invention, and creativity. These three points can be assessed from
                the prototype design carried, namely the production of salt and distillate water from waste that has
                been used to heat and evaporate seawater.
            </p>
            <p>
                In more detail, there are also three main points that are the background for the selection of topics for
                the UAD Chemical Engineering team in IIIEX 2022. First is the problem of waste, this issue has long been
                a problem in the global community and needs to be responded to immediately. One solution to reduce the
                volume of waste quickly is through incineration.Second is salt, Indonesia is a maritime country with the
                second longest coastline in the world, but facts on the ground show that our country still imports salt.
                According to data from the Central Statistics Agency (BPS), in 2021, Indonesia imported salt of 2.83
                million tons or worth 1.5 trillion rupiah. Third is the availability of clean water, especially in
                coastal areas that need special attention. It was noted that only 66.54% of coastal villages had access
                to clean water for sanitation needs.
            </p>
            <p>
                Based on these realities, the team then took the initiative to develop seawater desalination into
                distillate and salt water using waste fuel. Another challenge arises is the process of burning waste
                that produces air pollution or carbon dioxide. This is overcome by flowing smoke into the cultivation
                pond of Micoralga Botrycoccus brauni for carbon dioxide absorption through the process of
                photosynthesis. Mikoralga will also encourage the production of total lipids which in advanced
                processing of bio oil can be used as raw material for renewable energy, namely biodiesel.In its
                implementation, the UAD Chemical Engineering team admitted that it had experienced several obstacles
                such as limited coordination in the midst of individual activities so that it was necessary to divide
                time extra. In addition, there are also technical obstacles in the form of melting tools that have been
                assembled because they cannot withstand high temperatures. This certainly has an impact on spending
                additional funds that are not small, but the team can still handle it well.
            </p>
            <p>
                Abdul Aziz as the team leader revealed that study programs and universities provide full support and
                guidance during the competition. This was confirmed by Adi, "The study program will provide course
                recognition and grade improvement, while the university will provide prizes, the Student and Alumni
                Bureau (Bimawa) has also provided a lot of financial support. We are very grateful for that."
                Lastly, Adi hopes that this victory is just the first step of a million victories to come in the future.
                Students, especially Chemical Engineering and all study programs in general, are expected to be more
                motivated to innovate and work. (TSA)
            </p>
            <p>Source : <a target="_blank"
                    href="https://lldikti5.kemdikbud.go.id/home/detailpost/tim-uad-raih-medali-emas-dan-penghargaan-spesial-dalam-ajang-iiiex-2022">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->