<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>List of Winners</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <li>List of Winners</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section id="services" class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>-</span>
            <h2>Select by the year of the event you are participating in</h2>
            <div class="bar"></div>
        </div>
        <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s"
        >
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>2024</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a href="/kategori24" class="link uk-position-cover"></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>2023</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a
                        href="/kategori23"
                        class="link uk-position-cover"
                    ></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>2022</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a
                        href="https://drive.google.com/file/d/1gvTeroWiS2teMf207tJvgeS6drMJW-Q7/view"
                        class="link uk-position-cover"
                        target="_blank"
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>
