import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-digital-agency-three",
    templateUrl: "./digital-agency-three.component.html",
    styleUrls: ["./digital-agency-three.component.scss"],
})
export class DigitalAgencyThreeComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
