<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>List of Winners 2023</h1>
        <ul>
            <li><a href="/lowtahun">Previous Page</a></li>
            <li>Event Category</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section id="services" class="services-area uk-services uk-section">
    <div class="uk-container">
        <div class="uk-section-title section-title">
            <span>-</span>
            <h2>
                Choose based on the Category of the event you are participating
                in
            </h2>
            <div class="bar"></div>
        </div>
        <div
            class="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s"
        >
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>Special Award</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a
                        href="https://drive.google.com/file/d/1OUANcbikxfVwdXBPurr6Tug46POS-as8/view?usp=sharing"
                        target="_blank"
                        class="link uk-position-cover"
                    ></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>IIIEX OFFLINE</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a
                        href="/jenjangofl23"
                        class="link uk-position-cover"
                    ></a>
                </div>
            </div>
            <div class="item">
                <div class="single-services">
                    <div class="icon">
                        <i class="flaticon-think"></i>
                    </div>
                    <h3>IIIEX ONLINE</h3>

                    <i class="flaticon-right link-btn"></i>

                    <a
                        href="/jenjangonl23"
                        class="link uk-position-cover"
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>
