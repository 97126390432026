<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Media Coverage</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="listnews">News</a></li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>MTSN 3 Sanggau Wins Gold in International Scientific Competition 2023</h1>
            <img class="" src="../../../../assets/img/news/MTSN3News.WebP" alt="">
            <p>
                The Research Team of Madrasah Tsanawiyah (MTs) Negeri 3 Sanggau won first place by presenting a Gold
                Medal in an international scientific competition, Indonesia International Invention Expo (IIIEX) 2023 in
                the Life Science category organized by the Indonesian Young Scientists Association (IYSA) through online
                or online.The team consisting of Dheliza Amimi (team leader), Bunga Novriyanti and Nafaric Desfiandra
                are class IX MTs Negeri 3 Sanggau students with their supervisor Nanda Luthfia.
            </p>
            <p>
                The Principal of MTs Negeri 3 Sanggau Bawanta Ari Santosa said that in this scientific competition, the
                MTs Negeri 3 Sanggau research team took the Life Science category with the research title "The
                Effectiveness of Assertive Communication Education Campaign to Improve Students' Mental Health at MTs
                Negeri 3 Sanggau West Kalimantan"."Namely the Effectiveness of the Assertive Communication Education
                Campaign to Improve Student Mental Health at MTs Negeri 3 Sanggau, West Kalimantan, whose preparation
                and research are from July to August 2023," he said, Wednesday (30/08/2023).
            </p>
            <p>
                After hearing about the opening of the scientific competition, said Bawanta, a team was formed, then the
                team determined the title by adjusting the circumstances that were happening in the school."The research
                was carried out through several stages starting from the precycle, namely to see the real initial
                picture of the mental state of MTs Negeri 3 Sanggau students which was carried out in early July 2023.
                Then continued with cycle 1 and cycle 2 and during the research the supervisor also continued to guide
                students to practice percentages, because the competition that was followed on an international scale
                then the presentation was carried out using English, "he said.
            </p>
            <p>
                Bawanta revealed that after the research was completed, all the data obtained were then processed into a
                scientific paper article which was then made an English version to be further prepared as presentation
                material."The presentation will be held on Friday, August 25, 2023 at 16.35 until it will be completed
                online. In the presentation of the research results, the research team students were tested by an
                examination team which was part of a series of research assessments that had been carried out, "said
                Bawanta.On August 28, 2023, through the IIIE X 2023 Awarding Ceremony, the committee announced the
                research teams that won the Gold, Silver, and Bronze medals.
            </p>
            <p>
                "At the event, the MTs Negeri 3 Sanggau research team came out as the champion by winning a gold medal
                in the Life Science category," he said.Meanwhile, Nanda Luthfia, as the supervisor felt grateful and
                thankful to the head of MTs Negeri 3 Sanggau for his support and to the students participating in this
                scientific competition for their hard work together this achievement can be achieved and hopefully it
                can be a motivation for other students to take part in similar competitions next time.
            </p>
            <p>
                "There were 10 categories contested, namely Biotechnology, Chemistry, Environment, Food Science,
                Electronics and IoT, Social Science, Physics and Engineering, and Life Science which was attended by 217
                participating teams from various countries which were held offline and online by the Semarang State
                Polytechnic and we succeeded in becoming champions in one of the categories contested," he concluded.
            </p>
            <p></p>
            <p></p>
            <p>Source : <a target="_blank"
                    href="https://www.suarakalbar.co.id/2023/08/mtsn-3-sanggau-raih-emas-dalam-kompetisi-ilmiah-internasional/">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->