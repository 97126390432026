<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Media Coverage</h1>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="listnews">News</a></li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>MAN 1 Kudus Research Team Wins Bronze at Indonesia International Invention Expo 2023</h1>
            <img class="" src="../../../../assets/img/news/MAN1KUDUSNews.WebP" alt="">
            <p>
                Kudus (Kemenag) --- The research team of Madrasah Aliyah Negeri (MAN) 1 Kudus succeeded in presenting a
                bronze medal at the Indonesia International Invention Expo (IIIEX) 2023. This competition was organized
                by the Indonesian Young Scientist Association (IYSA), at the Semarang State Polytechnic.This competition
                is divided into several levels of education, ranging from elementary, secondary, to student and general
                levels. With a combination of online and offline format, this competition provides opportunities for
                students from various parts of the world to showcase the results of their creativity in front of judges
                and other participants.
            </p>
            <p>
                The MAN 1 Kudus Research Team consists of Riza Dwi Maylina as chairman, with three members, namely:
                Zahratul Wahidiyah, Adiba Zannuba Khafsoh, and Nur Zahratun Jannah. They participate in the Social
                Science category."To reach the final round, the MAN 1 Kudus research team must compete with delegates
                from the United States, South Korea, Iraq, Thailand, the Philippines, and Malaysia," explained Maylina
                after receiving the announcement of the winners, in Kudus, Tuesday (19/8/2023).
            </p>
            <p>
                According to Maylina, this journey to victory begins with the submission of an essay on August 7, 2023.
                IIIEX 2023 received more than 127 essays from participants representing diverse countries. The
                presentation session will take place on August 25, 2023. The MAN 1 Kudus team presented the results of
                their research in front of judges from Indonesia and Malaysia online via zoom."We present the results of
                research entitled "Joglo Pencu Kudus: History, Acculturation, and Cultural Preservation of the Holy
                Traditional House as a Manifestation of Javanese Beach Culture". This title is their first step to
                introduce the younger generation to the cultural heritage of Kudus which is manifested in the Joglo
                Pencu traditional house," said Maylina.
            </p>
            <p>
                According to Maylina, the choice of the title of this study was triggered by concerns about the fading
                condition of the Joglo Pencu Traditional House in Kudus. Many young people in Kudus do not understand
                the true meaning of Joglo Pencu Traditional House. "We chose this title with the hope that it can
                increase public awareness of the importance of preserving this cultural heritage which continues to be
                eroded by changing times," he said.Head of MAN 1 Kudus Taufik, admitted that he was proud and
                appreciated the achievements of his research team. "This achievement is a clear proof that the research
                spirit and dedication of MAN 1 Kudus students are able to compete at the international level. We hope
                this will be the first milestone for more brilliant achievements of the MAN 1 Kudus research team in the
                future," he said
            </p>
            <p>
                The MAN 1 Kudus research team, said Taufik, has proven its potential and competence on the international
                stage. They are not only the pride of the school, but also representatives of Indonesia who exude
                intelligence and creativity in the world of research."Stepping firmly and confidently, this team is
                ready to make even greater achievements in various upcoming national and international competitions, in
                line with the spirit of their research motto: "BRUSH, TRIPE, MAN 1 GREAT," he said.
            </p>
            <p>Source : <a target="_blank"
                    href="https://kemenag.go.id/internasional/tim-riset-man-1-kudus-raih-perunggu-indonesia-international-invention-expo-2023-Ru4dj">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->