import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-news",
    templateUrl: "./lowtahun.html",
    styleUrls: ["./lowtahun.scss"],
})
export class Lowtahun implements OnInit {
    constructor() {}

    ngOnInit() {}
}
